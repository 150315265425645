@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf');
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'JosefinSans';
    src: url('../fonts/JosefinSans-Regular.ttf');
    src: url('../fonts/JosefinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}