/* this will prevent trace 2 from toggling from the legend

/* hides the svg dom element that has the click handler responsible for toggling */
#chart .legend .traces .legendtoggle {
    display: none;
}
/* just for presentation: shows the default cursor insted of the text cursor */
#chart .legend .traces .legendtext {
cursor: default;
}
/* .main-svg {
    width: 415px !important;
    height: 200px !important;
    border-radius: 15px !important;
}*/
path.ygrid.crisp {


stroke: none !important;

}
/* g.point path {
    border-radius: 5px !important;
} */