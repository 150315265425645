.product_details-container tr th {
  font: 14px JosefinSans !important;
}
.link-page {
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
}
.nodata {
color: #7166bf;
margin-left: 10px;
}
.product_details-container_details tr th {
  font: 14px JosefinSans !important;
  font-weight: 800 !important;
  text-align: center;
}

.product_details_wrapper_table table tbody td:first-child {
  border-radius: 15px 0 0 15px !important;
}
.product_details_wrapper_table table tbody td:last-child {
  border-radius: 0 15px 15px 0 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  max-width: 100px !important;
}
.product_details-container thead {
  margin-bottom: 40px !important;
}
.product_details-container_details thead {
  margin-bottom: 40px !important;
}
.product-details-container .table table tbody tr {
  background-color: #fff;
  border: 1px solid #b2bbb9 !important;
}
.product-details-container .table table tbody td:first-child {
  border-radius: 15px 0 0 15px;
}
tr.tableView {
  font: 14px/50px JosefinSans !important;
  border: 1px solid #b2bbb9 !important;
  height: 60px !important;
}
.product-details-container .table table tbody tr td {
  border-radius: 15px 0px 0px 15px !important;
}
tr.tableView td {
  border-radius: 15px 0px 0px 15px !important;
}
/* .tableView > tr {
  padding: 20px !important;
  border: 1px solid #b2bbb9 !important;
  border-radius: 5px !important;
} */
.quote-details {
  background: #f3f4ff 0% 0% no-repeat padding-box;
  border-top-left-radius: 40px;
  padding: 30px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.customer-details-bg-wrapper {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
}
.customer-details-bg-wrapper-customer {
  background-color: #ffffff;
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 10px 30px 20px 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 20px;
}
.customer-details-bg-wrapper-customer-order-history {
  background-color: #ffffff;
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 10px 30px 10px 30px;

  margin-bottom: 20px;
}
.customerDetails-boder-line {
  border-right: 1px solid #b2bbb9;
}

.heading {
  font: 500 24px JosefinSans;
  color: #2d2424;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.heading_details {
  font: 500 24px JosefinSans;
  color: #2d2424;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  margin-top: 45px;
  margin-bottom: 25px;
}
.heading_sub {
  font: 500 18px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 25px;
}
.heading-main {
  font: 500 20px JosefinSans;
  color: #2d2424;
  text-align: left;
  display: block;
  margin-bottom: 40px;
}
.heading-main-total {
  font: 500 20px JosefinSans;
  color: #2d2424;
  text-align: center;
}
.heading-detail {
  font: 500 24px JosefinSans;
  color: #2d2424;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 40px;
}
.customer-details-wrapper {
  border-bottom: 1px solid #b2bbb9;
  margin-top: 30px;
}
.customer-details-wrapper:last-child {
  border-bottom: none !important;
  margin-top: 30px;
}
.heading-main-customer {
  font: 500 16px JosefinSans;
  color: #7166bf;
  text-align: left;
  margin-bottom: 35px;
  width: 200px;
  display: flex !important;
}
.heading-main-customer-main {
  font: 500 16px JosefinSans;
  color: #7166bf;
  text-align: left;
  margin-bottom: 15px;
  width: 200px;
}
.heading-main-inline-block {
  font: 500 20px JosefinSans;
  color: #2d2424;
  text-align: left;
  width: 110px;
  margin-bottom: 35px;
}
.customer-primary {
  font: 500 14px JosefinSans;
  color: #7166bf;
  text-align: left;
}
.customer-primary-customer {
  font: 500 14px JosefinSans;
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
  text-align: left;
  margin-top: 5px;
  width: 190px;
}
.shipping-address-wrapper {
  width: 200px;
}
.shipping-address-wrapper-green {
  width: 120px;
}
.shipping-address-wrapper-image {
  width: 275px;
  display: flex;
  margin-bottom: 0px;
}
.shipping-address-wrapper .customer-primary-sub {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.customer-primary-sub {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 35px;
  display: block;
  line-height: 20px;
}
.customer-primary-sub-machine-name {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 15px;
  display: block;
}
.customer-primary-sub-total {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: center;
  margin-bottom: 15px;
}
.customer-primary-description  {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 15px;
}
.customer-primary-sub-customer {
  font: 500 14px JosefinSans;
  color: rgb(76, 82, 184);
  text-align: left;
  margin-bottom: 35px;
  margin-top: 5px;
  width: 160px;
  padding: 0px !important;
  display: flex !important;
}
.customer-primary-sub-customer-toggle {
  font: 14px JosefinSans;
  color: #7166bf;
  margin-left: auto;
  margin-bottom: 35px;
  margin-top: 5px;
  width: 50px;
  padding: 0px !important;
  display: flex !important;
}
.customer-primary-sub-quote-toggle {
  font: 14px JosefinSans;
  color: #7166bf;
  margin-left: auto;
  margin-bottom: 35px;
  margin-top: 20px;
  width: 50px;
  padding: 0px !important;
  display: flex !important;
}
.customer-primary-sub-green {
}
.customer-primary-sub-green h6 {
  text-align: left;
  font: normal normal normal 16px JosefinSans;
  letter-spacing: 0px;
  color: #2e8e1d;
  padding-top: 2px;
  margin-bottom: 0px !important;
 
  /* opacity: 1; */
  /* position: absolute;
  top: 0px;
  left: 0px; */
}
.product_details-container {
  font-family: JosefinSans !important;
  border-top: 1px solid #b2bbb9;
  padding-top: 40px;
}
.product_details-container_details {
  font-family: JosefinSans !important;
  border-top: 1px solid #b2bbb9;
  padding-top: 0px;
  overflow-x: auto;
}
.product_details-container .table {
  font-family: JosefinSans !important;
  border-collapse: separate;
  border-spacing: 0px 20px !important;
}
.product_details-container_details .table {
  font-family: JosefinSans !important;
  border-collapse: separate;
  border-spacing: 0px 20px !important;
}
.product_details-container td:first-child {
  font-family: JosefinSans !important;
  background-color: #fff;
  border: 1px solid #b2bbb9 !important;
  border-radius: 10px 0px 0px 10px !important;
  border-right: none !important;
  padding-left: 25px !important;
}
.product_details-container_details td:first-child {
  font-family: JosefinSans !important;
  background-color: #fff;

  border: 1px solid #b2bbb9 !important;
  border-radius: 10px 0px 0px 10px !important;
  border-right: none !important;
  padding-left: 25px !important;
}
.product_details-container td:last-child {
  font-family: JosefinSans !important;
  background-color: #fff;
  border: 1px solid #b2bbb9 !important;
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
  padding-left: 25px !important;
}
.product_details-container_details td:last-child {
  font-family: JosefinSans !important;
  background-color: #fff;
  border: 1px solid #b2bbb9 !important;
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
  padding-left: 25px !important;
}



.padding-abandonded .product_details-container_details td:first-child {
  font-family: JosefinSans !important;
  background-color: #fff;
font-size: 12px !important;
  border: 1px solid #b2bbb9 !important;
  border-radius: 10px 0px 0px 10px !important;
  border-right: none !important;
  padding-left: 25px !important;
}
.padding-abandonded .product_details-container td:last-child {
  font-family: JosefinSans !important;
  background-color: #fff;
  font-size: 12px !important;
  border: 1px solid #b2bbb9 !important;
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
  padding-left: 25px !important;
}
.padding-abandonded .product_details-container_details td:last-child {
  font-family: JosefinSans !important;
  background-color: #fff;
  font-size: 12px !important;
  border: 1px solid #b2bbb9 !important;
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
  padding-left: 25px !important;
}

.padding-abandonded .product_details-container_details td {
  font-family: JosefinSans !important;
  background-color: #fff;
  padding-top: 20px !important;
  border-left: none !important;
  font-size: 12px !important;
  border-color: #b2bbb9 !important;
  border-style: solid !important;
  border-right: none !important;
  border-left: none !important;
  border-width: 1px !important;
  padding-left: 25px !important;
}
.product_details-container tr {
  height: 60px !important;
}
.product_details-container_details tr {
  height: 60px !important;
}
.padding-abandonded .product_details-container_details tr {
  height: 60px !important;
}
.product_details-container td {
  font-family: JosefinSans !important;
  background-color: #fff;
  padding-top: 20px !important;
  border-left: none !important;
  border-color: #b2bbb9 !important;
  border-style: solid !important;
  border-right: none !important;
  border-left: none !important;
  border-width: 1px !important;
  padding-left: 25px !important;
}
.product_details-container_details td {
  font-family: JosefinSans !important;
  background-color: #fff;
  padding-top: 20px !important;
  border-left: none !important;
  border-color: #b2bbb9 !important;
  border-style: solid !important;
  border-right: none !important;
  border-left: none !important;
  border-width: 1px !important;
  padding-left: 25px !important;
}
.right-border-line {
  border-right: 1px solid #b2bbb9;
}
.bottom-border-line {
  border-bottom: 1px solid #b2bbb9;
}
.total-cost-container {
  border: 1px solid #b2bbb9;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.total-cost {
  margin-top: 35px;
}
/* .total-cost-colum-width {
  width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
} */
.no-data-found {
  border: 1px solid #b2bbb9;
  border-radius: 15px;
  width: 100% !important;
}
.img-about {
  height: 90px;
  width: 90px;
  border-radius: 5px;
  margin-right: 10px;
}
.img-blank {
  height: 60px;
  width: 90px;
  border-radius: 5px;
  margin-right: 10px;
}
.ml-10 {
  margin-left: 5px;
}
.nav_link {
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
  font: 500 14px JosefinSans;
  text-align: left;
  margin-bottom: 35px;
  display: block;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
}
.customer-primary-sub-customer-heading {
  font: 500 14px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 35px;
  margin-top: 20px;
  width: 120px;
  padding: 0px !important;
  display: flex !important;
}
.customer-primary-sub-quote {
  font: 500 13px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 148px;
  display: inline-block;
}
.customer-primary-sub-quote-location {
  font: 500 13px JosefinSans;
  color: #2d2424;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
}

.eye_icon{
margin-left: auto;
display: flex;
}
.img_about {
  height: 28px;
  width: 44px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;

}
.img_about1 {
  height: 28px;
  width: 44px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
  position: relative;
  top: -5px;
  cursor: pointer;
}
.img_about1:hover + .hide {
  display: block;
  color: red;
  position: fixed;
  top: 20px !important;
  right: 25px;
  z-index: 9999;
  border-radius: 10px;
}
.hide {
  display: none;
  position: fixed;
  top: 20px !important;
  right: 25px;
  z-index: 999;
  border-radius: 10px;
}
.hide img {
  height: 350px !important;
  width: 350px !important;
  border-radius: 10px;
}
.img_about_details {
  height: 48px;
  width: 102px;
  border-radius: 28px;
  margin-right: 10px;
  object-fit: cover;
}

@media (max-width: 1241px) and (min-width: 1029px) {
  /* .product_details-container_details tr th {
    font: 10px JosefinSans !important;
    font-weight: 800 !important;
  }
  .padding-abandonded .product_details-container_details td:first-child {
    font-family: JosefinSans !important;
    background-color: #fff;
  font-size: 10px !important;
  font-weight: 700 !important;
    border: 1px solid #b2bbb9 !important;
    border-radius: 10px 0px 0px 10px !important;
    border-right: none !important;
    padding-left: 25px !important;
  } */
}
@media (max-width:1025px) {
  /* .product_details-container_details tr th {
    font: 10px JosefinSans !important;
    font-weight: 800 !important;
  }
  .padding-abandonded .product_details-container_details td:first-child {
    font-family: JosefinSans !important;
    background-color: #fff;
  font-size: 9px !important;
  font-weight: 700 !important;
    border: 1px solid #b2bbb9 !important;
    border-radius: 10px 0px 0px 10px !important;
    border-right: none !important;
    padding-left: 25px !important;
  }
  .padding-abandonded .product_details-container_details td {
    font-family: JosefinSans !important;
    background-color: #fff;
    padding-top: 20px !important;
    border-left: none !important;
    font-size: 10px !important;
    border-color: #b2bbb9 !important;
    border-style: solid !important;
    border-right: none !important;
    border-left: none !important;
    border-width: 1px !important;
    padding-left: 25px !important;
} */
  .quote-details {
    background: #f3f4ff 0% 0% no-repeat padding-box;
    border-top-left-radius: 40px;
    padding: 10px !important;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
  }
  .customer-primary-sub-quote {
    font: 500 14px JosefinSans;
    color: #2d2424;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 191px !important;
    display: inline-block;
    padding-right: 10px;
  }
  .customer-primary-sub-quote-location {
    font: 500 13px JosefinSans;
    color: #2d2424;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    
   display: inline-block;
       }
}
@media (max-width:769px) {

  .customer-primary-sub-quote {
    font: 500 14px JosefinSans;
    color: #2d2424;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 160px !important;
    display: inline-block;
    padding-right: 10px;
  }
  .customer-primary-sub-quote-location {
    font: 500 13px JosefinSans;
    color: #2d2424;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    
   display: inline-block;
  }
}