.form_control:focus {
  border: 1px solid #dedede !important;
  box-shadow: none !important;
}
.back_toLogin_color {
  color: #5c5da1 !important;
  cursor: pointer;
}
.verify_btn {
  width: 100%;
  margin-top: 30px;
}

.btn_login {
  width: 100%;
  font: 400 16px/20px JosefinSans;
  color: #ffffff;
  text-transform: uppercase;
  padding: 16px 32px;
  background-color: #353b3b;
  border-radius: 3px !important;
  margin-bottom: 3px;
  outline: none !important;
  border: none;
}
.btn_login:focus {
  box-shadow: none !important;
}
.btn_login:hover {
  color: #ffffff !important;
}

.login_bg {
  background-image: url("../../assets/img/login_bg_img.png");
  min-height: 100vh;
  padding: 20px 80px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.forgot_image {
  height: 100vh;
  width: auto;
}

.login_bg_forgot {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.login_banner_img {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  width: 100%;
}
.login_banner_img img {
  width: 90%;
  height: auto;
  margin-top: 5%;
}

.login {
  background-color: #fff;
  width: 100%;
  max-width: 450px;
  padding: 55px 55px 28px 55px;
  border-radius: 10px;
  border: 1px solid #d3cbcb;
  border-radius: 17px;
  margin: 12% auto 0;
}
.login .logo_img {
  margin-bottom: 35px;
  text-align: center;
}
.login .logo_img img {
  width: 100px;
  height: 49px;
}
.login .title_text {
  font: normal normal bold 36px/34px Montserrat;
  color: #5c5da1;
  margin-bottom: 15px;
  text-align: left;
}
.login .title_text1 {
  font: normal normal bold 21px/15px Montserrat;
  color: #5c5da1;
  margin-bottom: 15px;
  text-align: left;
}
.login .welcome_text {
  text-align: left;
  font: normal normal normal 16px/24px JosefinSans;
  letter-spacing: 0px;
  color: #353b3b;
  opacity: 1;
  margin-bottom: 15px;
}
.login .form_group {
  margin-bottom: 20px !important;
}
.login .form_control {
  border: 1px solid #dedede;
  border-radius: 1px !important;
  background-color: #f6f7f8;
  height: 47px;
  margin-bottom: 5px;
}
.login .form_control::-moz-placeholder {
  text-align: left;
  font: normal normal normal 14px/16px JosefinSans;
  letter-spacing: 0px;
  color: #9a9a9a;
  opacity: 1;
}
.login .form_control:-ms-input-placeholder {
  font: 500 12px/16px JosefinSans;
  color: #9a9a9a;
}
.login .form_control::placeholder {
  font: 500 12px/16px JosefinSans;
  color: #9a9a9a;
}
.login .error_text {
  font: 500 12px/16px JosefinSans;
  color: #dc3545;
  visibility: hidden;
  text-align: center;
}
.login .error_text.active {
  visibility: visible;
  text-align: center;
}
.login .password_icon {
  position: relative;
}
.login .password_icon1 {
  position: relative;
}
.login .password_icon img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.login .password_icon1 img {
  position: absolute;
  top: 45px;
  right: 10px;
  cursor: pointer;
}
.login .password_icon input {
  padding-right: 45px;
}
.login .forgot_password {
  margin-bottom: 10px;
  text-align: right;
}
.login .forgot_password a {
  font: 500 11px/16px JosefinSans;
  color: #5c5da1;
}
.login .privacy_policy {
  text-align: center;
  font: normal normal normal 12px/24px JosefinSans;
  letter-spacing: 0px;
  color: #353b3b;
  opacity: 1;
  margin-top: 25px;
}
.login .btn_login {
  background-color: #353b3b;
  border-radius: 1px;
  opacity: 1;
  color: white !important;
  outline: none !important;
  height: 50px;
  border: none !important;
}

.login .captcha {
  margin-bottom: 20px;
  background-color: #f6f7f8;
}

@media (max-width: 991px) {
  .btn_login {
    font: 400 14px/20px JosefinSans;
    padding: 10px 20px;
    margin-bottom: 1px;
  }

  .login_banner_img {
    display: none;
  }

  .login_bg {
    padding: 20px;
  }

  .login {
    padding: 30px;
    width: 100%;
    max-width: 400px;
    margin: 10% auto 0;
  }
  .login .logo_img {
    margin-bottom: 20px;
    text-align: center;
  }
  .login .logo_img img {
    width: 180px;
    height: 55px;
  }
  .login .title_text {
    font: bold 28px/26px Montserrat;
    margin-bottom: 10px;
  }
  .login .welcome_text {
    font: 400 14px/22px JosefinSans;
    color: #353b3b;
    margin-bottom: 10px;
  }
  .login .form_group {
    margin-bottom: 5px !important;
  }
  .login .form_control {
    height: 40px;
  }
  .login .error_text {
    font: 500 11px/16px JosefinSans;
  }
  .login .password_icon img {
    top: 15px;
    right: 15px;
  }
  .login .password_icon input {
    padding-right: 35px;
  }
  .login .forgot_password {
    margin-bottom: 5px;
  }
  .login .forgot_password a {
    font: 500 11px/16px JosefinSans;
    color: #5c5da1;
  }
  .login .captcha {
    margin-bottom: 10px;
  }
  .login .privacy_policy {
    font: 400 11px/24px JosefinSans;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .login {
    padding: 25px 25px 18px 25px;
  }
} /*# sourceMappingURL=index.css.map */
.otp_input_section {
  margin: 0 0 14px;
  position: relative;
}
.otp_input_section > div {
  display: flex;
  justify-content: space-between;
}
.otp_input_field input {
  border: 1px solid #dedede;
  border-radius: 1px;
  background: #f6f7f8;
  width: 42px !important;
  height: 44px;
}
.otp_input_field_admin input {
  width: 100% !important;
  height: 43px;
}
.otp_input_field > span {
  display: none;
}
.modal_title {
  text-align: center;
  color: #353b3b;
  font: 600 20px JosefinSans;
}
.sub_text {
  margin-bottom: 20px;
  text-align: center;
  color: #5c5da1;
  font: 300 16px JosefinSans;
}
.para p{
  font:  12px JosefinSans !important;
  line-height: 3px !important;
}
.para-text {
  font:  12px JosefinSans !important;
  line-height: 15px !important;
}
.para-text {
  margin-top: 25px;
  margin-bottom: 25px;
}