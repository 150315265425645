.management_sidebar {
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.management_sidebar::-webkit-scrollbar {
  display: none;
}

.management_sidebar .list_group {
  margin-top: 20px;
  margin-bottom: 180px;
  padding: 0px 15px;
}

.management_sidebar .list_group_item {
  border-color: transparent !important;
  padding: 8px 0px;
  font: 600 14px JosefinSans;
  color: #2d2424;
  margin-top: 15px;
  text-decoration: none;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
  position: relative;
}

.sb_arrow {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background-color: rgb(211, 211, 211);
  padding: 5px;
  border-radius: 60%;
 
}

.management_sidebar .list_group_item.active {
  background-color: transparent;
}

.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}


.management_sidebar .list_group_item:hover,
.management_sidebar .list_group_item.menu_active {
  color: #22205e !important;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%) !important;
  border-left: 5px solid #22205e !important;
}



.management_sidebar .list_group_item:hover .sb_image,
.management_sidebar .list_group_item.menu_active .sb_image {
  display: none;
}

.management_sidebar .list_group_item:hover .sb_image_active,
.management_sidebar .list_group_item.menu_active .sb_image_active {
  display: inline-block !important;
}

.management_sidebar .list_group_sub_item.menu_active {
  color: #22205e;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
}

.management_sidebar .list_group_item .sb_image,
.management_sidebar .list_group_item .sb_image_active {
  height: 18px;
  width: 20px;
  margin: 0px 20px;
  vertical-align: sub;
}

.sb_image_active {
  display: none;
}

.management_sidebar .list_group_sub_item {
  /* padding-left: 90px !important; */
  border-color: transparent !important;
  padding: 8px 10px 8px 22px;
  color: #22205e;
  text-decoration: none;
  font: 500 14px JosefinSans;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
}

.list_group_flush {
  margin-left: 35px;
  border-left: 1px solid #d5d5d8;
}

.management_sidebar .list_group_sub_item:hover {
  color: #4C52B8;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  /* box-shadow: 0px 15px 30px #4C52B833; */
  /* border-left: 5px solid #4C52B8 !important; */
}

.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
  position: relative;
  bottom: 20px;
  left: 10px;
}

.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px JosefinSans;
  color: #3f4544;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px JosefinSans;
  color: #3f4544;
  display: block;
}

.side_menu_img {
  background-color: red;
}

.logo {
  text-align: left;
  margin-top: 40px;
  padding-left: 25px;

}
.logo_brand {
  text-align: center;
}
.logo_brand img {
  height: 50px;
  width: 95px;
  object-fit: contain;
}

.logo img {
  height: 50px;
  width: 150px;
  object-fit: contain;
}
.showIn_mobile {
  display: none;
  padding: 0px !important;
  margin: 0px !important;
}
button {
  outline: 0px !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem;
}
.navbar-toggler {
  text-decoration: none;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem;
}
@media (max-width:769px) {
  .showIn_mobile {
    display: none !important;
  }
  .management_sidebar {
   display: visible !important;
  }
}

@media (max-width:759px) and (min-width:430px) {
  .showIn_mobile {
    display: visible !important;
  }
  .management_sidebar {
   display: none !important;
  }
}
@media (max-width:426px) {
  .showIn_mobile {
    display: visible;
  }
  .management_sidebar {
   display: none !important;
  }
}
.anchor:hover {
  color: #22205e !important;
}
.anchor:active {
  color: #22205e !important;
}

