.admin_management .row_margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.admin_management .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}
.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}
.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}
.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px JosefinSans;
  color: #2d2424;
  margin-top: 15px;
}
.management_sidebar .list_group_item:hover {
  color: #4c52b8;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #4c52b8 !important;
}
.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}
.management_sidebar .list_group_item.active {
  background-color: transparent;
}
.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}
.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px JosefinSans;
}
.management_sidebar .list_group_sub_item:hover {
  color: #4c52b8;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #4c52b8 !important;
}
.management_sidebar .admin_sidebar_footer {
  margin: 800px 25px 0px 25px;
}
.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px JosefinSans;
  color: #3f4544;
  margin-bottom: 0px !important;
}
.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px JosefinSans;
  color: #3f4544;
  display: block;
}
.management_details_contents {
  margin-bottom: 25px;
  width: 100%;
}

.management_details_contents .row {
  width: 100%;
}
.management_contents .management_details .management_details_contents label {
  font: 500 12px/20px JosefinSans;
  color: #2e2e2e;
}
.recentFilter {
  cursor: pointer;
  width: 100px;
  height: 30px;
  font-weight: 600;
  font-size: 12px;
}
