.form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }

  .form_control {
    border: 1px solid #CDD0E34B;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: "JosefinSans", inherit !important;
    color: #2E2E2E;
  }
  
  .form_control_select_dark {
    width: auto;
    font: normal normal 600 12px/20px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
    background-color: #E6E6E6;
    border-radius: 20px;
  }
  
  .btn {
    font: 600 12px JosefinSans;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
  }
  .btn img {
    vertical-align: bottom;
    margin-right: 5px;
  }
  
  .btn:focus {
    box-shadow: none !important;
  }
  
  .btn_borderd {
    color: #9A8776;
    background-color: transparent;
  }
  .btn_borderd:hover {
    color: #fff;
    background-color: #9A8776;
  }

  .btn_square{
    border-radius: 5px !important;
  }

  .btn_square:hover {
    text-decoration: none !important;
  }
  
  .btn_filled {
    color: #fff !important;
    background-color: #9A8776 !important;
  }
  .btn_filled:hover {
    text-decoration: underline;
  }
  
  .secondry_title {
    font: 500 12px/20px JosefinSans;
    color: #3F4544;
    margin-bottom: 12px !important;
  }
  
  input::-moz-placeholder {
    color: #b9b9b9;
  }
  
  input:-ms-input-placeholder {
    color: #b9b9b9;
  }
  
  input::placeholder {
    color: #b9b9b9;
  }
  
  .title_text_value {
    font: 500 12px/20px JosefinSans;
    color: #9A8776;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px JosefinSans;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_details_head{
    text-align: left;
    font: normal normal 600 16px/20px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
    opacity: 1;
    margin-bottom: 5px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px JosefinSans;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px JosefinSans;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px JosefinSans;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: #F3F4FF 0% 0% no-repeat padding-box;
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
  .management_contents .management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
  }
  .management_contents .management_header h1 {
    font: 600 24px/33px JosefinSans;
    color: #3F4544;
    margin-bottom: 0;
  }
  .management_contents .management_header h1 img {
    margin-right: 20px;
  }
  .management_contents .management_header .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    display: inline;
    width: auto;
    font-weight: 600 !important;
    font-size: 12px;
    color: #2D2424;
    padding: 5px 15px;
    height: auto;
  }
  .management_contents .management_details h4 {
    font: 600 16px/20px JosefinSans;
    color: #2E2E2E;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_radios {
    margin-bottom: 20px;
  }
  .management_contents .management_details .management_radios label {
    font: 600 14px/30px JosefinSans;
    color: #2E2E2E;
  }
  .management_contents .management_details .management_details_contents {
    margin-bottom: 25px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header a {
    font: 500 12px/20px JosefinSans;
    color: #4377BB;
  }
  .management_contents .management_details .management_details_contents .form_control {
    border: 1px solid #CDD0E3;
    border-radius: 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: "JosefinSans", inherit !important;
  }
  .management_contents .management_details .management_details_contents label {
    font: 500 12px/19px JosefinSans;
    color: #2E2E2E;
    display: block;
  }
  
  .management_contents .management_details .management_details_contents .btn:focus {
    outline: none !important;
  }
  .management_contents .management_details .management_details_contents input::-moz-placeholder {
    color: #B9B9B9;
  }
  .management_contents .management_details .management_details_contents input:-ms-input-placeholder {
    color: #B9B9B9;
  }
  .management_contents .management_details .management_details_contents input::placeholder {
    color: #B9B9B9;
  }
  
  .payment_btn {
    text-align: right;
  }
   .addadminextra{
    text-align: right;
    color:#A39486;
    font-size: 13px;
    cursor: pointer;
  }
  .management_contents .banner_tab_btn {
    padding: 0;
    
  }
  .banner_citys{
    display: flex;
  }
  .banner_city_list{
    border-bottom: none!important;
    margin-bottom: 0px!important;
  }
  .banner_tab_btn button{
    background: #efedef!important;
    box-shadow: 0px 15px 30px #9A877633;
    border-radius: 6px 6px 0px 0px!important;
    margin-right:10px;
    color:#A39486;
  }
  .banner_citys_update p{
    white-space: nowrap;
    font: normal normal 600 10px/20px JosefinSans;
    letter-spacing: 0px;
    color:#B9B9B9;
    margin-bottom: 3px;
    margin-left: 15px;
  }
  .banner_citys_update span{
    color:#000;
  }
  
  .custom-radio .custom-control-label::before {
    background-color: #FFF !important;
    border: 1px solid #9a8776;
  }
  .banner_tab_heading p{
    font: normal normal 600 16px/20px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
  }
 .banner_delete_btn p{
   margin-bottom: 0px;
   margin-right: 20px;
   color: #9A8776;
   font: 500 12px/20px JosefinSans;
   cursor: pointer;
   text-decoration: underline;
 }
 .banner_add_image p{
  font: normal normal 500 10px/20px JosefinSans;
  color: #4377BB;
  margin-bottom: 0;
  cursor: pointer;
 }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #9A8776 !important;
    border: 1px solid #9A8776 !important;
    box-shadow: none !important;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #9a8776 !important;
  }
  
  .onoffswitch {
    position: relative;
    width: 90px;
    margin-right: 30px;
  }
  
  .onoffswitch_checkbox {
    display: none;
  }
  
  .onoffswitch_label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ff0000;
    border-radius: 16px;
    margin-bottom: 0 !important;
  }
  
  .onoffswitch_inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch_inner:before, .onoffswitch_inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    font: 500 12px/34px JosefinSans;
    box-sizing: border-box;
  }
  .onoffswitch_inner:before {
    content: "Active";
    padding-left: 10px;
    background-color: transparent !important;
    color: #2E8E1D;
  }
  .onoffswitch_inner:after {
    content: "Inactive";
    padding-right: 10px;
    background-color: transparent !important;
    color: #ff0000;
    text-align: right;
  }
  
  .onoffswitch_switch {
    display: block;
    width: 20px;
    /* margin: auto; */
    background: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    bottom: 0;
    right: 65px;
    transition: all 0.3s ease-in 0s;
    height: 20px;
  }
  .onoffswitch_checkbox:checked + .onoffswitch_label {
    border-color: #2E8E1D;
  }
  
  .onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_inner {
    margin-left: 0;
  }
  .onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_switch {
    right: 5px !important;
    background-color: #2E8E1D;
    border-color: #2E8E1D;
  }/*# sourceMappingURL=index.css.map */

  .delivery_management_view_contents h6{
    margin-bottom: 30px;
    font: normal normal 600 14px/20px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
  }
  .delivery_management_view_contents h6:last-child{
    margin-bottom: 0px;
  }
  .delivery_management_view_contents h6 span{
    font: normal normal normal 14px/20px JosefinSans;
    color: #3F4544;
    min-width: 70px;
    min-width: 100px;
    display: inline-block;
  }
  .deilvery_management_details_head_title h5{
    font: normal normal 600 16px/38px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
    margin-bottom: 0;
  }
  .deilvery_management_switchs{
    position: relative;
    top:-10px
  }
  .abandoned_dashboard_box{
    box-shadow: 0px 10px 20px #0000000D;
  }
  .management_details .abandoned_dashboard_box h4{
    line-height: 32px;
  }
  .abandoned_dashboard_table_details p{
    font: normal normal 600 12px/15px JosefinSans;
    letter-spacing: 0px;
    color: #2D2424;
  }
  .abandoned_export_icon p{
    font: normal normal 600 10px/15px JosefinSans;
    color: #2D2424;
    margin-left: 10px;
  }
  .abandoned_dashboard_items{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #0000000D;
    border: 1px solid #CDD0E34B;
    border-radius: 24px;
    padding: 10px;
    width: 225px;
    min-width: 25%;
    align-items: center;
    margin-right: 30px;
  }
  .management_contents .management_header .region_selector{
    padding: 7px 15px!important;
    margin-right: 15px;
    font-weight: 500!important;

  }
  .management_contents .management_header .region_selector option:active{
    background-color: #000!important;
  }
  .abandoned_dashboard_items p{
    font: normal normal 500 10px/13px JosefinSans;
  color: #2D2424;
  margin-bottom: 5px;
  }
  .abandoned_dashboard_items h5{
    font: normal normal 600 13px/30px JosefinSans;
    letter-spacing: 0px;
    color: #2D2424;
    margin-bottom: 5px;
  }
  .abandoned_dashboard_items_graph p{
    font: normal normal 500 8px/10px JosefinSans;
    letter-spacing: 0px;
    color: #379E7A;
  }
  .abandoned_dashboard_items_graph p.abandoned_dashboard_items_red{
    color: #FF4141;
  }
  .abandoned_dashboard_items_box{
    width: 100%;
  }
  .abandoned_dashboard_items_greengraph img{
    margin-top: -20px;
  }
  .abandoned_checkout p{
    font: normal normal 500 12px/20px JosefinSans;
letter-spacing: 0px;
color: #3F4544;
margin-bottom: 5px;
  }
  .abandoned_checkout p a{
    font: normal normal 500 12px/20px JosefinSans;
    letter-spacing: 0px;
    color: #A29385;
    text-decoration: underline;
  }
  .abandoned_checkout h6{
    font: normal normal 600 14px/20px JosefinSans;
letter-spacing: 0px;
color: #3F4544;
  }
  .abandoned_checkout_status p{
    font: normal normal 600 12px/20px JosefinSans;
    letter-spacing: 0px;
    color: #3F4544;
  
  }
  .abandoned_checkout_status p span{
    font: normal normal 600 11px/14px JosefinSans;
    letter-spacing: 0px;
    color: #2E8E1D;
    background: #D4F2CF;
    border-radius: 50px;
    padding: 6px 15px;
  }
  .abandoned_checkout_link a{
    font: normal normal 500 12px/20px JosefinSans;
  letter-spacing: 0px;
  color: #3F4544!important;
  border: 1px solid #E8E1E1;
border-radius: 6px;
padding: 10px ;
width: 100%;
display: block;
  }
  /*# EMAIL TEMPLATE START */
  .email_template_select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .email_template_admin {
    padding: 0px;
  }
  .abandoned_checkout p.abandoned_checkout_product_name{
    text-decoration: underline;
    font: normal normal 600 12px/20px JosefinSans;
    letter-spacing: 0px;
    color: #9A8776;
  }
  .email_template_btn {
    border: 1px solid #9A8776;
    opacity: 1;
    width: 128px;
    height: 30px;
    text-align: center;
    font: normal normal normal 12px/20px JosefinSans;
    letter-spacing: 0px;
    color: #9A8776;
    opacity: 1;
    margin-top: 1px;
    background-color: white;
    margin-left: 20px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  /*# EMAIL TEMPLATE END */

  .management_product_link{
    text-decoration: underline;
    font: 500 14px/20px JosefinSans;
    color: #6E82E6;
    display: inline-block;
    margin-left: 15px;
  }

  .accordion_rating_rating_card{
    width: 100%;
  }

  .accordion_rating_card_header{
    text-align: left;
    background-color: transparent;
    padding: 20px 0 10px;
  }

  .accordion_rating_title{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .btn_section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .btn_title{
    font: 600 14px/20px JosefinSans;
    color: #3F4544;
    display: inline-block;
    margin-right: 15px;
  }

  .rating_btn{
    font: 500 14px/16px JosefinSans;
    color: #A7998C;
    border: 1px solid #A39486;
    border-radius: 5px;
    padding: 7px 14px 5px;
    background-color: transparent;
    cursor: pointer;
  }

  .rating_btn:hover{
    color: #A7998C !important;
  }

   /* Content Table  */
  .content_card{
    background: #FFFFFF;
    border: 1px solid #CDD0E34B;
    border-radius: 18px;
    padding: 20px 40px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .content_title{
    font: 600 12px/20px JosefinSans;
    color: #2D2424;
  }

  .content_text{
    font: 600 10px/20px JosefinSans;
    color: #B9B9B9;
  }

  .content_text_bold{
    color: #2D2424;
  }
  /* Content Table End*/
.landing_category_management label{
  font-weight: bold!important;
}
.custom_select {
  position: relative;
  font-family: Arial;
}

.custom_select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.add_page_save_button button {
  display: flex;
  margin-left: auto;
}
.password_eye_icon {
  position: absolute;
  top: 43px;
  right: 30px;
  cursor: pointer;
}

.company_password_eye_icon {
  position: relative;
  top: -47px;
  left: 261px;
}
.site_password_eye_icon {
  position: absolute;
  top: 42px;
  right: 20px;
  cursor: pointer;
}
/* multiple select box */
.secondry_title {
  font: 500 12px/20px JosefinSans;
  color: #2e2e2e;
}
.main_secondry_title{
  text-align: left;
  font: 600 16px/20px JosefinSans !important;
  letter-spacing: 0px;
  color: #2E2E2E;
  opacity: 1;
}
.margin_top_error {
  margin-top: 30px;
}
.label-margin-bottom {
  margin-bottom: 20px !important;
}
@media (max-width: 425px) {
  .management_contents {
    background: #F3F4FF 0% 0% no-repeat padding-box;
    border-top-left-radius: 40px;
    padding:20px 10px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
  .management_contents .management_header h1 img {
    margin-right: 20px;
    margin-left: 20px;
  }
  .site_password_eye_icon {
    position: absolute;
    top: 64px;
    right: 20px;
    cursor: pointer;
  }
}

/* forgot password */
/* Verify OTP */
.otp_input_section{
  margin: 0 0 14px;
  position: relative;
}
.otp_input_section > div{
 
  justify-content: space-between;
}
.login_otp_text{
  text-align: center;
}
.login_otp_text h4{
  font: normal normal 700 36px/34px Montserrat;
  color: #5c5da1;
  margin-bottom: 15px;
  text-align: left;
}

.login_otp_text p{
  font: normal normal normal 16px/28px JosefinSans;
  letter-spacing: 0px;
  color: #2E2E2E;
}
.login .title_text {
  color: #2E2E2E;
  margin-bottom: 15px;
}
.management_details_contents {
  background-color: white;
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  padding: 20px 80px 30px 80px;
  border-radius: 10px;
  border: 1px solid #d3cbcb;
}
.logo img {
  width: 105px;
  height: 60px;
  margin-bottom: 30px;
}
























