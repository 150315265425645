html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

}
/* .col-md-12 {
       padding-right: 0px !important; 
    padding-left: 0px !important;
}
 .col-lg-12 {
  padding-right: 0px !important; 
padding-left: 0px !important;
} */
input:focus {
  outline: none !important;
}

/* input::-webkit-calendar-picker-indicator {
  cursor: pointer;
} */
.spinner_btn {
  margin-right: 10px !important;

}

select {
  cursor: pointer !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.btn-link {
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
  
}

/* Login Modal */
.custom_modal .modal-dialog {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: 576px;
}

.custom_modal .modal-content {
  border-radius: 0;
}

.custom_modal .modal-body {
  padding: 0;
  position: relative;
}

.forgot_form_modal .modal-dialog {
  margin-top: 200px !important;
  text-align: center;
  width: 459px !important;
  height: 268px !important;
  border-radius: 11px;
  opacity: 1;
}

.forgot_btn {
  width: 410px;
  height: 50px;
  color: #A39486;
  border-radius: 8px 8px 8px 8px;
}

.login_btn,
.login_btn:hover,
.login_btn:focus {
  font: normal 12px/34px "JosefinSans";
  border-radius: 3px;
  background: #353b3b;
  color: #ffffff;
  text-transform: uppercase;
  min-height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  outline: none;
  position: relative;
  margin-bottom: 12px;
  outline: none;
  box-shadow: none;
}

.login_btn_admin,
.login_btn_admin:hover,
.login_btn_admin:focus {
  font: normal 20px/28px "JosefinSans";
  min-height: 58px;
  border-radius: 3px;
  max-width: 480px;
}


.forgetpassword_btn,
.forgetpassword_btn:hover,
.forgetpassword_btn:focus {
  font: normal 10px/16px "JosefinSans";
  padding: 0;
  text-decoration: underline;
  color: #4C52B8;
  border: none;
  outline: none;
  background: transparent;
}

button.login_otp,
button.login_otp:hover,
button.login_otp:focus {
  font: normal 10px/16px "JosefinSans";
  padding: 0;
  color: #4C52B8;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  box-shadow: none !important;
  background: transparent;
  white-space: nowrap;
}

.social_login_options {
  margin-top: auto;
  font: normal 8px/10px "JosefinSans";
  color: #9a9a9a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_login_options>* {
  margin: 0 4px;
}

.social_login_btn,
.social_login_btn:hover,
.social_login_btn:focus {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  border-radius: 12px;
  width: 23px;
}

.social_login_btn img {
  width: 100%;
}

.proceed_btn {
  font: normal 12px/16px "JosefinSans";
  color: #ffffff;
  border-radius: 3px;
  padding: 0 18px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #353b3b;
  border: none;
  outline: none;
}

.disabled {
  cursor: not-allowed;
}

/* Login Modal */

/* Logout */
.logout_btn,
.logout_btn:hover,
.logout_btn:focus {
  font: normal 20px/28px "JosefinSans";
  min-height: 58px;
  border-radius: 3px;
  background: #353b3b;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  position: relative;
  margin: 15px 15px 15px auto;
  outline: none;
  box-shadow: none;
}

/* Logout */

/* Ant Alert */
.ant-alert {
  align-items: flex-start !important;
  margin-bottom: 10px !important;
}

.anticon {
  margin-top: 4px;
}

/* Ant Alert */

.mt-32 {
  margin-top: 32px;
}
.cursor-pointer {
  cursor: pointer;
}
.settings-heading {
  cursor: pointer;
  text-align: left;
  font: normal normal 600 13px/5px JosefinSans;
  margin-bottom: 15px;
  letter-spacing: 0px;
  color: #3F4544;
  opacity: 1;

}

.theme-btn {
  font: 600 12px/20px JosefinSans;
  background-color: #4C52B8;
  border-radius: 7px;
  border: 1px solid #4C52B8;
  color: #fff;
  /* border-color: #4C52B8; */
  transition: background-color border 100ms ease;
  padding: 6px 22px;
  /* cursor: pointer; */
}

.theme-btn.rnd {
  border-radius: 7px;
}
.theme-btn.cnc {
  color: #4C52B8;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.theme-btn.cnc:hover {
  color: #4C52B8;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}

.theme-btn:hover,
.theme-btn:active {
  background-color: #747474;
  border-color: #747474;
  transition: background-color 200ms ease;
}

.theme-btn:disabled {
  background-color: #dddddd;
  border-color: #dddddd;
  cursor: default;
}

.theme-btn-link {
  font: 500 12px/20px JosefinSans;
  cursor: pointer;
  color: #4C52B8;
  text-decoration: underline;
  transition: color 100ms ease;
}

.theme-btn-link:hover {
  color: #747474;
  transition: color 150ms ease;
}

.theme-btn-outlined {
  font: normal normal 500 14px/20px JosefinSans;
  color: #A39486;
  background-color: transparent;
  border-radius: 7px !important;
  border-color: #4C52B8;
  transition: color border-color 100ms ease;
  padding: 10px 10px;
  cursor: pointer;
}

.theme-btn-outlined:hover {
  font: normal normal 500 14px/20px JosefinSans;
  color: #A39486;
  background-color: transparent;
  border-radius: 7px !important;
  border-color: #4C52B8;
  transition: color border-color 150ms ease;
}

.theme-form-control {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font-size: 14px !important ;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: JosefinSans !important;
  position: relative;
  
}
.theme-form-control-filter {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font-size: 14px !important ;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: JosefinSans !important;
  position: relative;
  top: 24px;
  width: 120px;
}
.theme-form-control:focus {
  box-shadow: none;
  border-color: #cdd0e34b !important;
  color: #495057;
  background-color: #fff;
}

.theme-form-control.with-icon {
  padding-left: 35px;
}

.theme-form-control.rnd {
  border-radius: 7px !important;
}

.theme-error-input,
.theme-error-input:focus,
.theme-error-blk {
  border-color: #ff7575 !important;
  box-shadow: 0 0 3px 1px #ff7575;
}

.theme-txt-xsm {
  font-family: "JosefinSans";
  font-size: 10px;
}

.theme-txt-sm {
  font-family: "JosefinSans";
  font-size: 12px;
}

.theme-txt-detail {
  font-family: "JosefinSans";
  font-size: 14px;
}

.theme-txt {
  font-family: "JosefinSans";
  font-size: 16px;
  line-height: 18px;
}

.theme-txt-disabled,
option:disabled {
  color: #dadada;
}

.theme-txt-subtitle {
  font: 500 12px/20px JosefinSans;
  color: #2e2e2e;
}

.theme-form-section-title {
  font: 600 16px/20px JosefinSans;
  color: #2e2e2e;
}

.theme-form-label {
  font: 500 12px/20px JosefinSans;
  color: #2e2e2e;
}

.theme-txt-title {
  font-size: 24px;
  line-height: 28px;
}

.theme-txt-main-title {
  font-size: 36px;
  line-height: 72px;
}

.theme-error-txt {
  color: #ff7575 !important;
}

.ant-spin-dot-item {
  background-color: #4C52B8;
}

.product-tabsbox .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  background: #4C52B833 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #4C52B833;
  border-radius: 6px 6px 0px 0px;
  color: #22205E;
  font:  13px/16px JosefinSans;
}

.product-tabsbox .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  background: #22205E;
}

.product-tabsbox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;

}

button.outline_btn {
  background: transparent;
  border-radius: 7px !important;
  color: #A39486;
  border: none;
  padding: 10px;
  font: normal normal 500 14px/20px JosefinSans;
}
button.outline_btn.no_outline_btn{
border:none;
font-size: 14px;
padding: 5px 15px;
}
button.outline_btn:hover {
  background: #A39486;
  color: #fff;
}
button.outline_btn.no_outline_btn:hover{
  background: transparent;
  color: #A39486;
}
.banner_region_select .ant-select-selection-overflow-item{
margin-top: 0px;
}
.outline_btn_add{
  background: transparent!important;
  color: #4C52B8!important;
  border-radius: 7px!important;
  padding: 12px 20px!important;
  line-height: 17px;
  margin-left: 0!important;
  font: normal normal 500 13px/20px JosefinSans !important;
  }
  .no_bottom_broder{
    border-bottom: 0!important;
  }
  .br-b-0{
    border-radius: 7px 7px 0 0!important;
  }
  .border-dote{
    border: 1px dashed #CDD0E3;
    border-radius: 5px;
    padding: 10px 15px;
  }
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 7px;
}
.add_city_btn{
  font: normal normal 600 12px/20px JosefinSans;
letter-spacing: 0px;
color: #C1A07F;
cursor: pointer;
}
.ant-switch-checked{
  background-color: #2E8E1D;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #4C52B8!important;
  border-color: #A39486!important;
}
.abandone_edit_notepad .rdw-editor-main{
min-height: 200px;
background-color: #EDEDF3;
padding:10px 20px;
border-radius: 11px;
}
@media (min-width: 768px) {
  .custom_modal .modal-dialog {
    max-width: 720px;
  }
}

@media (max-width: 7687px) {
  .custom_modal .modal-content {
    margin: 0 15px;
  }
}

.customCheck .custom-control-label::after {
  cursor: pointer !important;
}

.custom-control-label::after {
  cursor: pointer !important;
}

.custom-control-label span {
  cursor: pointer !important;
}

.password {
  position: absolute;
  right: 23px;
  top: 38px;
}

.wrapper {
  display: flex;
  width: 100%;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-top-left-radius: 40px;
}

.btn:focus {
  box-shadow: none !important;
}
#sidebar {
  position: relative;
  min-width: 260px;
  max-width: 260px;
  color: #fff;
  transition: all 0.3s;
  height: 100%;
  border-left: 1px solid #E4E4E4;
  padding: 30px;
  margin-left: 40px;
  background: #f6f6f5;
}

#sidebar #sidebarCollapse {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #EAEAEB;
  padding: 3px 12px;
  position: absolute;
  top: 45%;
  left: -15px;
}

#sidebar #sidebarCollapse img {
  padding-bottom: 3px;
  height: 15px;
  width: 6px;
  object-fit: cover;
}

#sidebar #sidebarCollapse .arrow_active {
  display: none;
}

#sidebar #sidebarCollapse .arrow {
  display: inline-block;
}

#sidebar.active #sidebarCollapse .arrow_active {
  display: inline-block;
}

#sidebar.active #sidebarCollapse .arrow {
  display: none;
}

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}

/* Shrinking the sidebar from 250px to 80px and center aligining its content*/
#sidebar.active {
  min-width: 120px;
  max-width: 120px;
  text-align: center;
}

/* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/
#sidebar .sidebar-header strong {
  display: none;
}

#sidebar.active .sidebar-header h3 {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

/* Same dropdown links padding*/
#sidebar.active ul ul a {
  padding: 10px !important;
}

/* Changing the arrow position to bottom center position, 
 translateX(50%) works with right: 50% 
 to accurately  center the arrow */
#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

/* Sidebar Activities */
#sidebar .dropdown-toggle {
  font: 500 14px/30px "JosefinSans";
  color: #000;
  background-color: #fff;
  border-color: #fff;
  width: 100%;
  height: 44px;
}

#sidebar .activites {
  box-shadow: 0px 20px 30px #8C8C8C1A;
}

#sidebar.active .activites {
  box-shadow: none !important;
}

#sidebar .dropdown-toggle img {
  margin-right: 15px;
  vertical-align: sub;
}

#sidebar .dropdown-toggle::after {
  margin-left: 57px;
}

#sidebar .dropdown-menu {
  transform: translate(0px, 55px) !important;
  width: 100%;
  max-width: 200px;
  border: none !important;
  box-shadow: 0px 20px 30px #8C8C8C1A;
}

#sidebar .dropdown-menu .dropdown-item {
  padding: 10px !important;
  font: 500 14px/30px "JosefinSans";
}

#sidebar .dropdown-menu .dropdown-item img {
  vertical-align: sub;
  margin-right: 12px;
}

#sidebar .text_hide {
  display: unset !important;
}

#sidebar.active .text_hide {
  display: none !important;
}
.dropDownArrow {
  position: relative;
  top: -18px;
  right: -104px;
  cursor: pointer;
}
#sidebar.active .dropdown-toggle::after {
  margin-left: 0px;
}

#sidebar.active {
  padding: 30px 10px !important;
  margin-left: 40px;
}
section option {
  background-color: white !important;
  box-shadow: 0px 10px 20px #0000000d !important ;
  border: 1px solid #CDD0E34B !important;
  border-radius: 5px !important;
  font: 14px JosefinSans !important;
  margin-top: 5px !important;
}
section option:hover {
  background: #4C52B8 !important;
  box-shadow: 0px 10px 20px #0000000d !important ;
  border: 1px solid #CDD0E34B !important;
  border-radius: 5px !important;
  font: 12px JosefinSans !important;
  margin-top: 5px !important;
  font-weight: 600 !important;
  color: #2D2424 !important;
}
#sidebar.active .dropdown-menu {
  max-width: 70px !important;
  min-width: 70px !important;
  inset: 0px 0px auto 15px !important;
}

#sidebar.active .dropdown-menu .dropdown-item {
  text-align: center !important;
}

#sidebar.active .dropdown-menu .dropdown-item img {
  margin-right: 0px !important;
}

#sidebar.active .dropdown-menu .dropdown-item {
  padding: 7px !important;
}

#sidebar .dropdown .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 20px;
}

#sidebar .dropdown .btn {
  text-align: left;
}

#sidebar .activites .dropdown {
  width: 100%;
}

/* Sidebar Activities End*/
.card-box {
  border: 1px solid #CDD0E34B;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
}

.form_add_select .ant-select-selector {
  height: inherit;
  padding: 0;
  border-radius: 0.3rem !important;
}

.form_add_select .ant-select-multiple .ant-select-selection-item {
  background: #DEDEDE !important;
}

.form_add_select .ant-select-multiple .ant-select-selection-item-content {
  font: normal normal medium 14px/13px JosefinSans !important;
  color: #2E2F3C !important;
}

button.tranpr_bgbtn {
  border: none !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
  color: #4C52B8;
  font: normal normal 600 16px/20px JosefinSans;
  letter-spacing: 0px;
}

.tax_gst_btn .custom-control-input:checked~.custom-control-label::before {
  border-color: #4C52B8 !important;
  background-color: #4C52B8 !important;
}

.form_add_select .anticon {
  vertical-align: middle !important;
  margin-top: -2px !important;
  zoom: 1.3;
}

.ant-select-selection-overflow-item {
  margin: 5px;
}

/* Order Progressbar */
.recent_activites {
  display: block;
  text-align: left;
  padding: 10px 0px;
  width: 100%;
}

.recent_activites .recent_activites_header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.recent_activites .recent_activites_header h4 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

.recent_activites .recent_activites_header .dots {
  color: black;
  font-size: 25px;
  vertical-align: middle;
  padding-bottom: 12px;
}

.recent_activites .recent_activites_values {
  width: 100%;
}

.recent_activites .recent_activites_values .recent_activites_details {
  width: 100%;
  min-height: 80px;
  height: 100%;
  max-height: 150px;
  display: flex;
  min-width: 100px;
  position: relative;
  z-index: 0;
}

.recent_activites .recent_activites_values:not(:last-child) .recent_activites_details:before {
  content: "";
  position: absolute;
  width: auto;
  height: 100%;
  border-left: 1px dashed #4C52B8;
  top: 0px;
  left: 20px;
  z-index: -1;
}

/* .recent_activites .recent_activites_values .recent_activites_details.rm-line:before {
  border: none !important;
} */

/* .recent_activites .recent_activites_values .recent_activites_details.completed:before {
  border-top: 1px solid green !important;
} */

.recent_activites .recent_activites_values .recent_activites_details .recent_activites_img {
  height: 40px;
  width: 40px;
  /* padding: 5px; */
  /* border: 1px solid #DFDFDF;
  border-radius: 50%; */
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  box-sizing: border-box;
  margin-right: 10px;
}

.recent_activites .recent_activites_values img {
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
  width: 40px;
}

.recent_activites .recent_activites_values .recent_activites_name {
  font: 500 10px/16px JosefinSans;
  color: #5C5DA1;
}

.recent_activites .recent_activites_values .recent_activites_status {
  font: 600 10px/16px JosefinSans;
  color: #2D2424;
}

.recent_activites .recent_activites_values .recent_activites_date {
  font: 500 10px/16px JosefinSans;
  color: #9F9C9C;
  margin-top: 5px;
  margin-bottom: 20px;
}

.store-timepicker .ant-picker {
  width: 100%;
}

.store-addimages h4 {
  font-size: 12px !important;
  color: #2e2e2e !important;
}

.recent_activites .recent_activites_values .recent_activites_date span {
  display: block;
}

#sidebar.active .recent_activites_values .recent_activites_details {
  justify-content: center;
}

#sidebar.active .recent_activites_values:not(:last-child) .recent_activites_details:before {
  left: 45px;
}
.dashboard-progressbar .CircularProgressbar .CircularProgressbar-trail{
  stroke: #f8040400;
}
.firstprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #C8AC92;
}
.firstprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #C8AC92;
}
.secondprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #BE6A4A;
}
.secondprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #BE6A4A;
}
.thirdprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #677B7B;
}
.thirdprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #677B7B;
}
.forthprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #9F9F9B;
}
.forthprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #9F9F9B;
}
/* Order Progressbar End*/
.add_discount_form .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 7px;
}
.add_discountpost{
  float: right;
  border-radius: 7px!important;
  padding: 5px 20px!important;
}
.br-7{
  border-radius: 7px!important;
}
.banner_tab_btn button.active{
  background:#A39486!important;
  color: #fff;
}
/* Quick Details */
.quick_details {
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 18px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.quick_details .quick_details_title {
  font: 600 12px/18px JosefinSans;
  color: #2D2424;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E9EC;
}

.quick_details .quick_details_contents {
  display: flex;
  align-items: flex-start;
}

.quick_details .quick_details_contents:not(:last-child) {
  margin-bottom: 20px;
}

.quick_details .quick_details_contents img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.quick_details .quick_details_contents .quick_details_contents_details .quick_details_contents_title {
  font: 600 10px/16px JosefinSans;
  color: #2D2424;
}

.quick_details .quick_details_contents .quick_details_contents_details .quick_details_contents_description {
  font: 400 10px/20px JosefinSans;
  color: #AEAEAE;
}

#sidebar.active .quick_details {
  max-width: 70px;
  margin: 15px auto;
}

/* Quick Details end */
/* Ant Table */
.ant-table {
  background-color: transparent !important;
}

.ant-table .ant-table-thead tr th {
  background-color: transparent !important;
  border-bottom: none !important
}

.ant-table .ant-table-thead .ant-table-cell::before {
  background-color: transparent !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: transparent !important;
}
 th{
  padding-right: 20px !important;
}
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 11px !important;
}

/* Ant Table End*/

.usermanage_table_filter_columns.ant-dropdown-link {
  display: inline-block;
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 12px/20px JosefinSans;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 20px;
}

.columns_list {
  padding: 15px 45px 15px 15px !important;
}

.columns_list ul {
  padding-left: 0px !important;
}

.columns_list ul li span:last-child {
  padding-left: 10px !important;
  font: 600 12px/20px JosefinSans;
  /* position: relative; */
}

.columns_list ul li span:last-child img {
  position: absolute;
  top: 5px;
  right: -30px;
  display: none;
}

.columns_list ul li span:last-child:hover img {
  display: inline-block !important;
}

/* Action */
.action {
  display: inline-block;
  position: relative;
}

.action_header {
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 11px/20px JosefinSans;
  cursor: pointer;
  white-space: nowrap;
}

.action_header img {
  margin-left: 5px;
  width: 10px;
}

.action_contents {
  background-color: #ffffff;
  position: absolute;
  z-index: 1000;
  padding: 15px;
  margin-top: 2px;
  font: 600 12px/20px JosefinSans;
  box-shadow: 0px 10px 20px #00000033;
  border-radius: 8px;
  display: none;
  min-width: 260px;
}

.action:hover .action_contents {
  display: inline-block !important;
}

.action_contents span {
  display: block;
}

.action_contents .action_contents_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.action_contents_header_h2 {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.action_contents_header_h2_active {
  color: #4C52B8;
}

.action_contents_header .action_contents_btn {
  border: 1px solid #4C52B8;
  background-color: transparent;
  color: #4C52B8;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 10px;
  text-decoration: none !important;
  cursor: pointer;
}

/* Action End*/

/* Filters */
.filter {
  display: inline-block;
  position: relative;
}

.filter:hover .card {
  display: inline-block !important;
}

.filter_heading {
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 12px/20px JosefinSans;
  cursor: pointer;
}

.card {
  width: 320px;
  position: relative !important;
  z-index: 1000;
  /* display: none; */
  margin-top: -4px;
}



.card_contents {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px #00000033;
  border-radius: 8px;
  padding: 25px;
  width: auto;
}

.card_info {
  font: normal normal normal 10px/20px JosefinSans;
  color: #AEAEAE;
}

.card_dropdown:nth-child(even) .card_dropdown_heading {
  margin-left: 10px;
}

.card_dropdown {
  min-width: 140px;
  margin-bottom: 10px;
}

.card_dropdown:not(:first-child) {
  display: none;
  /* position: absolute;
  top: 77px;
  left: 81px;
  z-index: 1; */
}
.card_dropdowns:not(:first-child) {
  /* display: none; */
  position: absolute;
  top: 77px;
  left: 81px;
  z-index: 1;
}
.card_dropdowns{
background-color: white;
padding: 20px;
border-radius: 10px;
box-shadow: 0px 15px 30px #4C52B833;
}
.show {
  display: inline-block !important;
}

.card_dropdown:hover .card_dropdown_contents {
  display: inline-block !important;
}

.card_dropdown_heading {
  border: 1px solid #E9E9F0;
  border-radius: 6px;
  position: relative;
  width: auto!important;
}

.filter_dropdown .card {
  border: none !important;
  flex-direction: row-reverse;
}


.ant-dropdown-menu {
  padding: 0;
  margin-top: 3px;
}

.filter_dropdown .card .card_contents .card_dropdown:nth-child(even) {
  min-width: 140px;
  /* margin-left: 10px; */
}
.filter_dropdown .card .card_contents .card_dropdowns_dashboard:nth-child(even) {
  min-width: 285px;
  /* margin-left: 10px; */
}
.card_dropdowns_dashboard {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 15px 30px #4C52B833;
}

.card_dropdown_heading img {
  position: absolute;
  right: 10px;
}

.card_dropdown_contents {
  border: 1px solid #E9E9F0;
  border-radius: 6px;
  padding: 10px;
  min-width: 150px;
  max-height: 150px;
  overflow-y: auto;
  display: none;
  position: absolute;
  z-index: 1020;
  background-color: #ffffff;
 /*  scrollbar-width: thin;
  scrollbar-color: #e3e3e341 transparent; */
}
.card_dropdown_contents::-webkit-scrollbar {
  width: 3px;
}
.card_dropdown_contents::-webkit-scrollbar-track {
  background: transparent;
}
.card_dropdown_contents::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.473);
}

.card_dropdown_contents span {
  display: block;
  font: 500 12px/17px JosefinSans;
}
.card_dropdown_contents.card_slect_hover span:hover{
  background: #5C5DA1; 
  color: #fff;
  cursor: pointer;
}

.card_dropdown_contents span:not(:last-child) {
  padding-bottom: 2px;
  padding-top: 2px;
}
.card_selct_checkbox input[type="checkbox"]{
position: relative;
top: -4px;
}

.parentCheckbox {
  position: relative;
  top: 49px;
  left: 11px;
  cursor: pointer;
  z-index: 9;
}
span.select-table-css-container:hover {
color: #2D2424 !important;
text-decoration: underline !important;
}
span.select-table-css-container:active {
  color: #2D2424 !important;
  text-decoration: underline !important;
  }
  span.select-table-css-container {
    color: #2D2424 !important;
    
    }

.filter_dropdown .form_control {
  /* margin-left: 10px; */
  padding: 10px 0px;
  border-radius: 6px !important;
  font: 500 12px/20px JosefinSans !important;
  width: 100%;
  border: 1px solid #E9E9F0 !important;
}

.filter_dropdown .card .card_contents .card_dropdown:nth-child(even) .form_control {
  margin-left: 10px;
  width: 90%;
}

.filter_dropdown .card .card_contents .card_dropdown .card_dropdown_contents label {
  margin-bottom: 0px !important;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}
.filter_dropdown .card .card_contents .card_dropdown .card_dropdown_contents input {
 position: relative;
 margin-bottom: 0px !important;
 top: -3px;
}
.card_dropdown_no_padding{
  padding: 0;
  margin: 0;
}
.card_dropdown_contents.card_dropdown_no_padding span{
  line-height: 14px;
}
/* Filters end */

/* Order Reschedule Datepicker */
.order_events_comments_datepicker input {
  border: none !important;
  padding: 10px 50px 10px 20px !important;
}

.order_events_comments_datepicker .ant-picker-focused {
  border: none !important;
}

.order_events_comments_datepicker .ant-picker {
  border: none !important;
  border-radius: 8px !important;
  padding: 0px !important;
  cursor: pointer;
}

.order_events_comments_datepicker .ant-picker-suffix .anticon-calendar svg {
  display: none !important;
}

.order_events_comments_datepicker .ant-picker-suffix::before {
  content: 'Reschedule';
  font: 400 12px/16px JosefinSans;
  padding: 8px 10px 7px;
  border: 1px solid #A39486;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #A39486;
  color: #ffffff;
}

.ant-table-column-sorter {
  margin-top: -6px !important;
}

.datefilter.filter_dropdown .card_contents {
  padding: 0;
  width: 100px;
  background: transparent;
  box-shadow: none;
}

.datefilter.filter_dropdown .card_dropdown {
  /* margin-bottom: 0;
  top: -4px;
  right: 2px; */
  padding-right: 15px;
}

.datefilter.filter_dropdown .form-control {
  margin-left: 0;
  max-width: 145px;
}

.datefilter.filter_dropdown .card {
  width: 140px;
}

.acc_card_header .card-header {
  padding: 0 !important;
}

/* Ant Tree */
.ant-tree {
  padding: 20px;
  border: 1px solid #CDD0E3;
  border-radius: 8px;
}

.ant-tree-title {
  font: 500 14px/13px JosefinSans;
  color: #2E2F3C;
}

.ant-tree-list {
  position: relative;
}

.ant-tree-checkbox {
  position: absolute;
  right: 0;
}

.ant-tree-switcher-noop~.ant-tree-checkbox {
  margin-right: 30px !important;
}

 /* Content Table  */
 .content_card{
  background: #FFFFFF;
  border: 1px solid #CDD0E34B;
  border-radius: 18px;
  padding: 20px 40px;
  margin-bottom: 10px;
  cursor: pointer;
}

.content_title{
  font: 600 12px/20px JosefinSans;
  color: #2D2424;
}

.content_text{
  font: 600 10px/20px JosefinSans;
  color: #B9B9B9;
}

.content_text_bold{
  color: #2D2424;
}
/* Content Table End*/
.ant-table-content {
  /* border: 1px solid #CDD0E3;
  border-radius: 8px;
  margin-top: 10px; */
}


/* CMS Page Tab Customization */
.content_tab .ant-tabs-tab{
  padding: 0px !important;
}

.content_tab .ant-tabs-tab .ant-tabs-tab-btn{
  font: 400 12px/13px JosefinSans !important;
  color: #A39486 !important;
  padding: 10px !important;
  background-color: #4C52B833;
  border-radius: 6px 6px 0px 0px;
}

.content_tab .ant-tabs-tab + .ant-tabs-tab{
margin: 0 0 0 3px !important;
}

.content_tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.content_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
  background-color: #A39486 !important;
}
/* CMS Page Tab Customization End*/
/* loader align */
.loader{
  text-align: center;
}

.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum","tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0px 20px !important;
}

.ant-dropdown-hidden, .ant-dropdown-menu-hidden, .ant-dropdown-menu-submenu-hidden {
  display: none !important;
}

.graph-layer {
  overflow-x: auto !important;
}
.graph-layer::-webkit-scrollbar {
  height: 5px !important;
}
/* Track */
.graph-layer::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

/* Handle */
.graph-layer::-webkit-scrollbar-thumb {
  background: #2b308725 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.graph-layer::-webkit-scrollbar-thumb:hover {
  background: #2b308725 !important;
  cursor: pointer !important;
}
.settings-container {
  border: 1px solid #b2bbb9;
  border-radius: 10px ;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  margin-right: 25px;
  width: 295px;
  padding-left: 20px;
  padding-right: 20px;
  height: 130px;
}
.settings-bg-wrapper {
  border: 1px solid #b2bbb9;
  border-radius: 10px ;
  box-shadow: 0px 10px 20px #0000000D;
  padding: 25px;
  background-color: white;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
}
.btn-outline-primary:hover {
  background-color: #22205E !important;
  border-color: #22205E !important;
  color: white !important;
}
.product-tabsbox .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  margin-left: 30px !important;
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #22205E !important;
}
.ant-btn:hover, .ant-btn:focus {
  color: #ffffff !important;
  border-color: #22205E !important;
  background: #22205E !important;
}
.ant-btn, .ant-btn {
  color: #22205E !important;
  border-color: #22205E !important;
  background: transparent !important;
  font: 16px/23px JosefinSans !important;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px !important;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font: 16px/30px JosefinSans !important;
  line-height: 1.4;
}
.settings_margin_bottom {

  margin-bottom: 20px;
}
.form_input {
  border: none !important;
}
.password_eye {
  position: absolute;
  top: 28px;
  right: 41px;
}
.freight_select {
  width:100px;
}
.navbar-toggler-icon {
  background-image: url(../assets/img/menu.svg) !important;
}
button {
  outline: 0px !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem;
}
.navbar-toggler {
  text-decoration: none;
  outline: none !important;
  box-shadow:none !important;
}
@media (max-width:1205px) {
  .loading-spin {
    margin-left: 30vh;
    margin-top: 10vh;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: none !important;
    box-shadow: 0 0 0 0.25rem;
  }
  .theme-form-control {
    border: 1px solid #cdd0e3;
    border-radius: 10px;
    font-size: 14px !important ;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: JosefinSans !important;
    position: relative;
    top: 24px;
    
  }
  
  .settings-container {
    border: 1px solid #b2bbb9;
    border-radius: 10px ;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    margin-right: 25px;
    width: 240px !important;
    padding-left: 20px;
    margin-bottom: 20px !important;
  }
  .settings_margin_bottom {
    margin-bottom: 0px;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
  font: 12px/30px JosefinSans !important;
}

}
@media (max-width:769px) {
  .theme-error-txt {
    color: #ff7575 !important;
    margin-top: 30px !important;
  }
  .logout {
    position: absolute;
    top: 25px;
    right: 20px;
  }
  .loading-spin {
    margin-left: 30vh;
    margin-top: 10vh;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: none !important;
    box-shadow: 0 0 0 0.25rem;
  }
  .theme-form-control {
    border: 1px solid #cdd0e3;
    border-radius: 10px;
    font-size: 14px !important ;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: JosefinSans !important;
    position: relative;
    top: 24px;
   
  }
  
  .settings-container {
    border: 1px solid #b2bbb9;
    border-radius: 10px ;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    margin-right: 25px;
    width: 310px !important;
    padding-left: 20px;
    margin-bottom: 20px !important;
  }
  .settings_margin_bottom {
    margin-bottom: 0px;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
  font: 12px/30px JosefinSans !important;
}

}
@media (max-width:426px) {
  .btn-outline-primary {
    background-color: transparent !important;
    border-color: #22205E !important;
    color: #22205E !important;
    font: 12px/30px JosefinSans !important;
    margin-bottom: 20px !important;
}
  .loading-spin {
    margin-left: 30vh;
    margin-top: 10vh;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: none !important;
    box-shadow: 0 0 0 0.25rem;
  }
  .theme-form-control {
    border: 1px solid #cdd0e3;
    border-radius: 10px;
    font-size: 14px !important ;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: JosefinSans !important;
    position: relative;
    top: 24px;
   
  }
  
  .settings-container {
    border: 1px solid #b2bbb9;
    border-radius: 10px ;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    margin-right: 25px;
    width: 310px !important;
    padding-left: 20px;
    margin-bottom: 20px !important;
  }
  .settings_margin_bottom {
    margin-bottom: 0px;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
  font: 12px/30px JosefinSans !important;
}

}
@media (max-width:376px) {
  .settings-container {
    border: 1px solid #b2bbb9;
    border-radius: 10px ;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    margin-right: 25px;
    width: 265px !important;
    padding-left: 20px;
    margin-bottom: 20px !important;
  }
  .settings_margin_bottom {
    margin-bottom: 0px;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
  font: 12px/30px JosefinSans !important;
}

}
@media (max-width:325px) {
  .logout {
    position: absolute;
    top: 25px;
    right: 20px;
}
  .loading-spin {
    margin-left: 5vh;
    margin-top: 10vh;
    padding: 100px 0px;
  }
  .settings-container {
    border: 1px solid #b2bbb9;
    border-radius: 10px ;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    margin-right: 25px;
    width: 205px !important;
    padding-left: 20px;
    margin-bottom: 20px !important;
    height: max-content;
  }
  .settings-heading {
    cursor: pointer;
    text-align: left;
    font: normal normal 600 13px/16px JosefinSans;
    margin-bottom: 15px;
    letter-spacing: 0px;
    color: #3F4544;
    opacity: 1;
  
  }
  .settings_margin_bottom {
    margin-bottom: 0px;
}
.btn-outline-primary{
  background-color: transparent !important;
  border-color: #22205E !important;
  color: #22205E !important;
  font: 12px/30px JosefinSans !important;
}

}
@media (max-width:2560px) and (min-width:768px) {
  .showIn_mobile {
    display: none !important;
  }
}

@media (max-width:425px) {
  .theme-error-txt {
    color: #ff7575 !important;
    margin-top: 30px !important;
  }
  .logout {
    position: absolute;
    top: 25px;
    right: 20px;
  }
  .showIn_mobile {
    display: visible;
  }
  .management_sidebar {
   display: none !important;
  }
}
.showIn_mobile {
  padding: 0px !important;
  margin: 0px !important;
}
.description {
  font: 12px/20px JosefinSans !important;
  
 
}
.settings-icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-right: 10px;
}
.ant-modal-header {
  padding: 20px 25px 10px 25px !important;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0 !important;
}
.ant-modal-footer {
  padding: 24px 20px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
}
.container-fluid {
  padding-right: var(--bs-gutter-x,.75rem) ;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  width: max-content !important;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.abandodedDetail {
  color: #22205E !important;
  font: 15px/30px JosefinSans !important;
}
.padding-abandonded {
  padding: 20px;
}
.ptl-4 {
  padding-top: 20px;
  padding-left: 20px;
}


.management_sidebar .list_group_item_sub:hover,
.management_sidebar .list_group_item_sub.menu_active {
  color: #22205e !important;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%) !important;
  border-left: 5px solid #22205e !important;
  cursor: pointer !important;
}
.img_about1 {
  height: 28px;
  width: 44px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
  position: relative;
  top: -5px;
  cursor: pointer;
}
.img_about1:hover + .hide {
  display: block;
  color: red;
  position: fixed;
  top: 20px !important;
  right: 25px;
  z-index: 9999;
  border-radius: 10px;
}
.hide {
  display: none;
  position: fixed;
  top: 20px !important;
  right: 25px;
  z-index: 9999;
  border-radius: 10px;
}
.hide img {
  height: 350px !important;
  width: 350px !important;
  border-radius: 10px;
}

.img_about2 {
  height: 48px;
  width: 102px;
  border-radius: 28px;
  margin-right: 10px;
  object-fit: cover;
  position: relative;
  top: -5px;
  cursor: pointer;
}
.img_about2:hover + .hide2 {
  display: block;
  color: red;
  position: fixed;
  top: 20px !important;
  right: 25px;
  z-index: 9999;
  border-radius: 10px;
}
.hide2 {
  
    display: none;
    position: fixed;
    top: 20px !important;
    left: 885px;
    z-index: 9999;
    border-radius: 10px;

}
.hide2 img {
  height: 350px !important;
  width: 350px !important;
  border-radius: 10px;
}